@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
  --primary-color: #ffffff; /* Default color */
  --secondary-color: #000000; /* Default color */
}

/* General + Font styles */
.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Tiny5', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  flex-grow: 1;
}

h1,
h2 {
  font-family: 'Jersey 15';
  text-transform: uppercase;
}

p {
  font-family: "Tiny5";
}

.jersey-15-regular {
  font-family: "Jersey 15", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

.main-content {
  background: linear-gradient(to right, var(--primary-color), var(--secondary-color));
  color: white;
  flex-grow: 1;
  overflow-y: auto;
  padding-left: 1rem;
}

@media (max-width: 1000px) {
  .main-content {
    background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
    margin-top: 0; /* Ensure no top margin */
    padding-left: 1rem;
  }
}

/* Add a new class for when the sidebar is present */
.main-content.with-sidebar {
  margin-left: 105px;
}

@media (min-width: 1000px) {
  .main-content.with-sidebar {
    margin-left: 105px;
  }
}

.nft-item {
  position: relative;
}

.nft-item img {
  transition: transform 0.2s;
}

/* .nft-item.selected {
  border: 2px solid rgba(0, 255, 0, 0.8);
} */

.nft-item .absolute {
  position: absolute;
  top: 10px;
  right: 10px;
}

.nft-item .bg-green-500 {
  background-color: rgba(0, 255, 0, 0.5);
}

.nft-item .bg-blue-500 {
  background-color: #1c64f2;
}

.nft-item .text-red-500 {
  color: red;
}

.nft-item.selected .hover\:opacity-100:hover {
  opacity: 1;
}

.nft-item .hover\:scale-105:hover {
  transform: scale(1.00);
}


.rubik-lines-regular {
  font-family: "Rubik Lines", system-ui;
  font-style: normal;
  font-weight: 100;
}

.tiny5-regular {
  font-family: "Tiny5", sans-serif;
  font-style: normal;
  font-weight: 400;
  text-transform: uppercase;
}

/* Define font faces */
@font-face {
  font-family: 'Tiny5';
  src: url('./fonts/Tiny5-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Jersey 15';
  src: url('./fonts/Jersey15-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik Lines';
  src: url('./fonts/RubikLines-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Remaining Styles */
/*
.card {
  align-items: center;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 350px;
  justify-content: center;
  overflow: hidden;
  padding: 0px;
  position: relative;
  width: 350px;
  z-index: 2;
}
*/
.card-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card-container .glass-tab+.glass-tab-details {
  display: block;
}

.checkmark-container {
  transition: opacity 0.2s;
}

.checkmark-container:hover .checkmark {
  opacity: 0;
}

.checkmark-container:hover .hover-visible {
  opacity: 1;
}

.circle {
  align-items: center;
  background: var(--circle-bg);
  border-radius: inherit;
  box-shadow: var(--circle-shadow);
  display: flex;
  height: var(--circle-diameter);
  justify-content: center;
  left: var(--switch-offset);
  position: absolute;
  transition: var(--circle-transition);
  width: var(--circle-diameter);
  z-index: 1;
}

.clear-button {
  position: absolute;
  top: 2px;
  right: 2px;
  cursor: pointer;
  z-index: 10;
  /* Ensure the button is on top of the image */
}

/* .content-container {
  margin-top: 440px;
  padding: 0px;
} 
*/

.double-bounce1,
.double-bounce2 {
  animation: sk-bounce 2.0s infinite ease-in-out;
  background-color: #333;
  border-radius: 50%;
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}

.double-bounce2 {
  animation-delay: -1.0s;
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
  }
}

.dropdown-menu {
  border-radius: 5px;
  padding: 10px;
}

.empty-cell {
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}

.empty-cell:hover {
  background-color: rgba(211, 211, 211, 0.5);
  /* Light gray */
}

.feed-page-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.fixed-card {
  background: transparent;
  left: 50%;
  max-width: 350px;
  padding: 16px;
  position: fixed;
  top: 30px;
  transform: translateX(-50%);
  width: 100%;
  z-index: 1000;
  margin-bottom: 50px;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.glass-button {
  backdrop-filter: blur(5px);
  border: 0px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: white;
  font-weight: bold;
  padding: 10px 20px;
  transition: background 0.3s, border 0.3s, box-shadow 0.3s;
  font-family: "Tiny5";
  text-transform: uppercase;
}

.glass-button:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.glass-button:disabled {
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
  cursor: not-allowed;
}

.glass-button.blue {
  background-color: #3b82f6;
}

.glass-button.clear {
  align-items: center;
  backdrop-filter: none;
  border: 0px solid rgba(255, 255, 255, 0);
  border-radius: 0px;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0);
  color: white;
  font-weight: bold;
  justify-content: center;
  padding: 0px 0px;
  transition: background 0.3s, border 0.3s, box-shadow 0.3s;
}

.glass-button.green {
  background-color: rgba(0, 255, 0, 0.7);
}

.glass-button.red {
  background-color: #af0000b3;
}

.glass-button.clear span {
  display: block;
  text-align: center;
}

/* .glass-card {
  align-items: center;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: center;
  margin: 15px;
  overflow: hidden;
  padding: 0px;
  position: relative;
  width: 350px;
  z-index: 2;
}
*/

/*
.glass-tab {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  color: white;
  margin-top: 0px;
  text-align: center;
  transition: all 0.3s ease;
}
*/

.glass-tab-details {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  color: white;
  margin-top: 0px;
  padding: 0px;
  padding-top: 8px;
  text-align: center;
  transition: all 0.3s ease;
  width: 80%;
  height: 40px;
}

.glass-tab-links {
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  color: white;
  margin-top: 0px;
  padding: 0px;
  padding-top: 3px;
  text-align: center;
  transition: all 0.3s ease;
  width: 80%;
  height: 40px;
}


.glass-tab-links .inline {
  align-items: center;
  display: inline-flex;
}

/*
.grid-cell {
  overflow: hidden;
  padding-top: 100%;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.grid-cell img {
  cursor: pointer;
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  transition: transform 0.3s ease;
  width: 100%;
}
  

.grid-display {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
}
*/
.grid-feed-container {
  align-items: center;
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.grid-feed-item {
  margin-bottom: 20px;
  width: 100%;
}

.grid-item {
  cursor: pointer;
  margin-bottom: 20px;
  width: 100%;
}

.grid-item:hover {
  transform: scale(1.05);
}

.grid-item img {
  height: auto;
  width: 100%;
}

.grid-name-container {
  min-height: 28px;
}

.grid-navigation {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.grid-navigation:hover {
  background-color: rgba(255, 255, 255, 1);
}

.grid-navigation-left {
  left: 10px;
}

.grid-navigation-right {
  right: 10px;
}

.grid-navigation img {
  width: 24px;
  height: 24px;
}


.grid-rows {
  display: grid;
  gap: 2px;
  grid-template-columns: repeat(3, 1fr);
  height: 100%;
  width: 100%;
}

.info-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

input:checked+.slider {
  background-color: #2196F3;
}

input:checked+.slider:before {
  transform: translateX(22px);
}


/* .modal {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.0);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}*/

/*
.modal-container {
  position: fixed;
  top: 10; // Changed from 1 to 0 to position it correctly 
  left: 0;
  width: 100%;
  height: 350px; // This ensures the modal container does not exceed the card height 
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
}
*/

.modal {
  max-width: 90%;
  max-height: 94%;
  margin: auto;
  /* This centers the modal */
}

/* .modal-image {
  width: 100%;
  cursor: pointer;
  height: auto;
  object-fit: contain;
  max-height: 90%; /* This ensures the image does not exceed the view height 
}

.modal img {
  cursor: pointer;
  width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 90%;
}
*/




nav {
  align-items: center;
  background-color: #111827;
  color: white;
  display: flex;
  flex-direction: column;
  height: 48px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1001;
}

nav a {
  color: white;
  padding: 0.5rem 0;
  text-decoration: none;
  transition: color 0.3s ease;
}

nav a:hover {
  color: #ff7e5f;
}

nav button {
  transition: background 0.3s ease;
}

nav .dropdown {
  background-color: #444;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  color: white;
  padding-top: 10px;
  position: absolute;
  right: 0;
  top: 48px;
  width: 200px;
}

nav .dropdown a {
  color: white;
  display: block;
  padding: 8px 16px;
  text-decoration: none;
}

nav .dropdown a:hover {
  background-color: #555;
}

.occupied {
  background-color: rgba(0, 255, 0, 0.2);
}

.panel {
  background: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 2px;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.panel:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5), 0 0 30px rgba(0, 123, 255, 0.5);
  transform: translateY(-10px);
}

.panel-content {
  opacity: 0.8;
}

.panel-content h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.panel-content p {
  font-size: 1rem;
  margin: 0;
}

.placeholder {
  background-color: rgba(255, 255, 255, 0.1);
}

.placeholder-cell {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 2px;
  height: 100%;
  width: 100%;
}


.selected .checkmark {
  position: absolute;
  top: 10px;
  right: 10px;
}


.selected .remove-x {
  position: absolute;
  top: 10px;
  left: 10px;
}

.nft-item:hover .remove-x {
  display: block;
}

.selected {
  background-color: rgba(211, 211, 211, 0.5);
  /* Light gray */
}

/*
.sidebar-container {
  background-color: #2c3e50;
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  top: 0;
  width: 150px;
}
*/

.single-grid-card {
  align-items: center;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  display: flex;
  height: 350px;
  justify-content: center;
  overflow: hidden;
  padding: 0px;
  position: relative;
  width: 350px;
  z-index: 2;
}

.single-grid-feed-container {
  align-items: center;
  background: transparent;
  display: flex;
  flex-direction: column;
  padding: 0px;
}

.slider {
  align-items: center;
  background: var(--switch-bg);
  border-radius: 999px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: var(--switch-height);
  position: relative;
  transition: var(--switch-transition);
  width: var(--switch-width);
}

.spinner {
  height: 40px;
  margin: 100px auto;
  position: relative;
  width: 40px;
}

.switch {
  display: inline-block;
  --circle-bg: #fff;
  --circle-checked-shadow: -1px 1px 2px rgba(163, 163, 163, 0.45);
  --circle-diameter: 18px;
  --circle-shadow: 1px 1px 2px rgba(146, 146, 146, 0.45);
  --circle-transition: var(--switch-transition);
  --effect-bg: var(--circle-bg);
  --effect-border-radius: 1px;
  --effect-height: calc(var(--effect-width) / 2 - 1px);
  --effect-transition: all .2s ease-in-out;
  --effect-width: calc(var(--circle-diameter) / 2);
  --icon-checkmark-color: var(--switch-checked-bg);
  --icon-checkmark-size: 10px;
  --icon-cross-color: var(--switch-bg);
  --icon-cross-size: 6px;
  --icon-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
  --switch-bg: rgb(131, 131, 131);
  --switch-checked-bg: rgb(0, 218, 80);
  --switch-height: 24px;
  --switch-offset: calc((var(--switch-height) - var(--circle-diameter)) / 2);
  --switch-transition: all .2s cubic-bezier(0.27, 0.2, 0.25, 1.51);
  --switch-width: 46px;
}

.switch input {
  display: none;
}

.switch input:checked+.slider {
  background: var(--switch-checked-bg);
}

.switch input:checked+.slider .checkmark {
  transform: scale(1);
}

.switch input:checked+.slider .cross {
  transform: scale(0);
}

.switch input:checked+.slider::before {
  left: calc(100% - var(--effect-width) - (var(--effect-width) / 2) - var(--switch-offset));
}

.switch input:checked+.slider .circle {
  box-shadow: var(--circle-checked-shadow);
  left: calc(100% - var(--circle-diameter) - var(--switch-offset));
}

.switch svg {
  height: auto;
  position: absolute;
  transition: var(--icon-transition);
}

.switch .checkmark {
  color: var(--icon-checkmark-color);
  transform: scale(0);
  width: var(--icon-checkmark-size);
}

.switch .cross {
  color: var(--icon-cross-color);
  width: var(--icon-cross-size);
}

.text-input {
  width: 270px;
}

.tooltip-content {
  white-space: normal;
  max-width: 350px;
  word-wrap: break-word;
  padding: 10px;
  border-radius: 4px;
  background-color: #333;
  color: white;
  z-index: 1000 !important;
}

.tooltip-arrow {
  color: #f3f4f6;
}

.topbar {
  height: 30px; /* Adjusted height */
}

.topbar-button {
  position: absolute;
  right: 16px;
  top: 20px;
}

.truncate {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes scroll-left {
  from {
    transform: translateX(105%);
  }

  to {
    transform: translateX(-105%);
  }
}

/* Media queries */
@media (max-width: 1000px) {
  .sidebar-container {
    /* display: none; */
  }


  .main-content {
    background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
    margin-top: 0; /* Ensure no top margin */
    padding-left: 1rem;
  }

  nav {
    height: 60px;
    overflow-y: auto;
    padding-bottom: 100px; /* Increased padding for mobile */
  }

  .dropdown-menu {
    min-height: calc(100vh - 64px); /* Adjust based on your header height */
    height: -webkit-fill-available;
    overflow-y: auto;
    padding-bottom: 100px; /* Increased padding for mobile */
  }
}



.z-9000 {
  z-index: 9500;
}


.hidden {
  display: none;
}

.hidden-on-mobile {
  display: none;
}

@media (min-width: 1000px) {
  .hidden-on-mobile {
    display: block;
  }
}

.searchBarMobileWrapper {
  position: fixed;
  top: var(--search-bar-top, 45px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 100%;
}

.searchBarMobile {
  width: 100%;
}

@media (min-width: 769px) {
  .topbar {
    display: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

